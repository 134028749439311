import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import { Mneuitem } from "../data/menu";
import { useCookies, Cookies } from 'react-cookie';
import { Link, SvgIcon } from '@mui/material';


export default function TemporaryDrawer() {
    const [state, setState] = React.useState({
        left: false,
    });
    const [cookies] = useCookies(['rule']);

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const list = (anchor) => (
        <Box
            sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <Box textAlign="center" p={3}>
                <a href='/dashboard'>
                <img src="/logo.png" width="90%"></img>
                </a>
            </Box>
            <List>

                {Mneuitem.map((item) => {

                    return (
                        item.per.includes(cookies.rule) == true &&
                        <ListItem disablePadding >
                            <Link href={item.url} underline='none' >
                                <ListItemButton>
                                    <ListItemIcon>
                                        <img style={{ color: '#fff' }} width={35} src={item.icon}></img>
                                    </ListItemIcon>
                                    <ListItemText color="white" style={{ color: '#fff' }} primary={item.name} />
                                </ListItemButton>
                            </Link>

                        </ListItem>
                    )
                })}

            </List>
            <Divider />

        </Box>
    );

    return (
        <div>
            {['left'].map((anchor) => (
                <React.Fragment key={anchor}>
                    <Box onClick={toggleDrawer(anchor, true)}>
                        <MenuIcon />
                    </Box>
                    <Drawer
                        anchor={anchor}
                        open={state[anchor]}
                        onClose={toggleDrawer(anchor, false)}
                    >
                        {list(anchor)}
                    </Drawer>
                </React.Fragment>
            ))}
        </div>
    );
}