import React from "react";
import { config } from "../data/config";
import { useCookies } from 'react-cookie';
import { useEffect } from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import axios from "axios";
import { Box, CardMedia, Grid, Stack, TextField } from "@mui/material";
import Swal from "sweetalert2";
export default ({ component, parent }) => {
    const [cookies, setCookie] = useCookies(['token', 'rule']);

    const [file, setFile] = React.useState();
    const uploadRef = React.useRef();
    const statusRef = React.useRef();
    const loadTotalRef = React.useRef();
    const progressRef = React.useRef();
    const [listfile, setlistfile] = React.useState([]);

    const UploadFile = () => {
        const file = uploadRef.current.files[0];
        setFile(URL.createObjectURL(file));
        var formData = new FormData();
        formData.append("file", file);
        formData.append("component", component);
        formData.append("parent", parent);

        var xhr = new XMLHttpRequest();

        xhr.upload.addEventListener("progress", ProgressHandler, false);
        xhr.addEventListener("load", SuccessHandler, false);
        xhr.addEventListener("error", ErrorHandler, false);
        xhr.addEventListener("abort", AbortHandler, false);
        xhr.open("POST", config.url + '/filemanager');
        xhr.setRequestHeader('Authorization', cookies.token);
        xhr.setRequestHeader('Access-Control-Allow-Origin', '*');

        xhr.send(formData);
    };

    const ProgressHandler = (e) => {
        // loadTotalRef.current.innerHTML = `uploaded ${e.loaded} bytes of ${e.total}`;
        var percent = (e.loaded / e.total) * 100;
        progressRef.current.value = Math.round(percent);
        // statusRef.current.innerHTML = Math.round(percent) + "% uploaded...";
    };

    const SuccessHandler = (e) => {
        // statusRef.current.innerHTML = e.target.responseText;
        progressRef.current.value = 0;

        load()
    };
    const ErrorHandler = () => {
        // statusRef.current.innerHTML = "upload failed!!";
    };
    const AbortHandler = () => {
        //statusRef.current.innerHTML = "upload aborted!!";
    };
    const load = async () => {
        try {
            var path = `${config.url}/filemanager/${parent}?component=${component}`;
            let res = await axios({
                method: 'get',
                url: path,
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': `multipart/form-data`,
                    'Authorization': cookies.token
                }
            });
            setlistfile(res.data.message);
            // setData(res.data.message)
        } catch (error) {
            setlistfile(null);
        }
    }
    const totop = async (item) => {
        try {
            var path = `${config.url}/filemanager/totop/${item}`;
            let res = await axios({
                method: 'put',
                url: path,
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': `multipart/form-data`,
                    'Authorization': cookies.token
                }
            });
            load()
            // setData(res.data.message)
        } catch (error) {
        }
    }
    const editext = async (item, title) => {
        Swal.fire({
            title: "Title",
            input: "text",
            inputValue: title,
            inputAttributes: {
                autocapitalize: "off",

            },

            showCancelButton: true,
            confirmButtonText: "Change Name",
            showLoaderOnConfirm: true,
            preConfirm: async (login) => {
                try {
                    var path = `${config.url}/filemanager/title/${item}`;
                    let res = await axios({
                        method: 'put',
                        data: {
                            title: login
                        },
                        url: path,
                        headers: {
                            'Access-Control-Allow-Origin': '*',
                            'Content-Type': `multipart/form-data`,
                            'Authorization': cookies.token
                        }
                    });
                    load()
                    // setData(res.data.message)
                } catch (error) {
                }
            },
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: `Success`,

                });
            }
        });

    }

    const deleteitem = async (item) => {
        try {
            var path = `${config.url}/filemanager/${item}`;
            let res = await axios({
                method: 'delete',
                url: path,
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': `multipart/form-data`,
                    'Authorization': cookies.token
                }
            });
            load()
            // setData(res.data.message)
        } catch (error) {
        }
    }
    const todown = async (item) => {
        try {
            var path = `${config.url}/filemanager/todown/${item}`;
            let res = await axios({
                method: 'put',
                url: path,
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': `multipart/form-data`,
                    'Authorization': cookies.token
                }
            });
            load()
            // setData(res.data.message)
        } catch (error) {
        }
    }
    useEffect(() => {
        load();
    }, []);
    return (
        <div className="App">
            <input type="file" name="file" ref={uploadRef} onChange={UploadFile} />

            <Box p={1}>

                <Stack direction={'row'} spacing={2}>

                    <label>
                        <progress ref={progressRef} value="0" max="100" />
                    </label>
                    <Box>
                        <Button onClick={(e) => { load() }} size="small" variant="contained">Refresh</Button>

                    </Box>
                </Stack>


            </Box>
            {/* <p ref={statusRef}></p>
      <p ref={loadTotalRef}></p> */}
            {listfile != null &&
                <>
                    <Box mt={2}>
                        <Grid container>
                            {

                                listfile.map((row) => (

                                    <Grid xs={'auto'} pt={3}>
                                        <Card variant="outlined">

                                            <CardContent>
                                                {(row.suffix == '.jpg' || row.suffix == '.png' || row.suffix == '.jpeg' || row.suffix == '.bmp') &&

                                                    <CardMedia
                                                        component="img"
                                                        height="194"

                                                        width="200"
                                                        image={`${config.url}/${row.url}`}
                                                        alt={row.name}
                                                    />
                                                }
                                                {(row.suffix == '.mp4' || row.suffix == '.mov' || row.suffix == '.mpeg' || row.suffix == '.avi' ) &&
                                                    <video width="320" height="240" controls>
                                                        <source  src={`${config.url}/${row.url}`} />

                                                    </video>
                                                }
                                                <Typography textAlign={'center'} sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                                    {row.title} {row.suffix}
                                                </Typography>

                                            </CardContent>
                                            <CardActions>

                                                <Button size="small" onClick={(e) => { todown(row['_id']['$oid']) }} >
                                                    <img src='/assets/arrowleft.svg' width={20} ></img>
                                                </Button>
                                                <Button size="small" onClick={(e) => { editext(row['_id']['$oid'], row['title']) }}>
                                                    <img src='/assets/editlabel.svg' width={20}></img>
                                                </Button>
                                                <Button size="small" onClick={(e) => { deleteitem(row['_id']['$oid']) }}>
                                                    <img src='/assets/trash.svg' width={20}></img>
                                                </Button>
                                                <Button size="small" onClick={(e) => { totop(row['_id']['$oid']) }}>
                                                    <img src='/assets/arrowright.svg' width={20}></img>
                                                </Button>
                                            </CardActions>
                                        </Card>
                                    </Grid>

                                ))
                            }

                        </Grid>
                    </Box>
                </>

            }
            {/* <img src={file} alt="" style={{ width: "300px", height: "100px" }} /> */}
        </div>
    );
};
