import { Box, Breadcrumbs, Select, MenuItem, Button, Container, Grid, Link, Paper, Stack, TextField, Typography } from "@mui/material";
import { useForm } from 'react-hook-form';
import axios from "axios";
import { config } from "../../data/config";
import Swal from 'sweetalert2'
import { useCookies } from 'react-cookie';
import { useEffect } from "react";
import React from "react";
import Singlefileuploader from "../../component/singlefileuploader";
export default function Setting() {
    const [cookies, setCookie] = useCookies(['token', 'rule']);
    const [setting, setSetting] = React.useState(null);
    const [cateogry, selectCat] = React.useState(null);


    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
    const submit = async (data) => {
        var path = `${config.url}/setting/2`;
       
        try {
            let res = await axios({
                method: 'put',
                url: path,
                data: setting,
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': `application/json`,
                    'Authorization': cookies.token
                }
            });

         Swal.fire('saved')


        } catch (error) {
            console.log('error')
            Swal.fire(error.response.data.message)
            error.response.data.error == null ?
                Swal.fire(error.response.data.message)
                : Swal.fire({
                    title: error.response.data.message,
                    html: `<div>
                ${Object.keys(error.response.data.error).map((field) => (
                        ` <div key=${field}>
                 ${error.response.data.error[field].map((message) => (
                            `<a style="font-size:15px"  key={message}> ${message}</a>`
                        ))}
               </div>`
                    ))}
              </div>`

                })
        }

    }
    const load = async () => {
        var path = `${config.url}/setting`;
        try {
            let res = await axios({

                url: path,
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': `multipart/form-data`,
                }

            });
            setSetting(res.data)
        } catch (error) {
            console.log(error);
            alert('error load group')
        }

    }
    useEffect(() => {
        load();
    }, []);
    return (
        <Container dir="rtl">
            <Box pb={3} pt={3}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" href="/dashboard">
                        داشبورد
                    </Link>

                    <Typography color="text.primary">تنظیمات</Typography>
                </Breadcrumbs>
                <Typography variant='h1' color={'gray'} fontSize={34}>تنظیمات</Typography>
            </Box>
            <Paper>
                <Box dir="rtl">
                    <form onSubmit={handleSubmit((data) => submit(data))} >
                        <Box p={4}
                            borderRadius={2}>
                            {setting != null &&
                                <Grid container>
                                   

                                    <Grid xs={12} md={6}>
                                        <Box p={1}>
                                            <label>نام</label>
                                            <TextField
                                                autoComplete={false}
                                                defaultValue={setting.name}
                                                fullWidth
                                                dir="rtl"
                                                style={{ textAlign: 'right' }}
                                                onChange={(e) => setSetting({ ...setting, name: e.target.value })}
                                                 variant={'filled'} type="text" ></TextField>
                                        </Box>
                                    </Grid>
                                    <Grid xs={12} md={6}>
                                        <Box p={1}>
                                            <label>instagram</label>
                                            <TextField
                                                autoComplete={false}
                                                defaultValue={setting.instagram}
                                                fullWidth
                                                dir="ltr"
                                                onChange={(e) => setSetting({ ...setting, instagram: e.target.value })}
                                                 variant={'filled'}

                                                type="text" ></TextField>
                                        </Box>
                                    </Grid>
                                    <Grid xs={12} md={6}>
                                        <Box p={1}>
                                            <label>website</label>
                                            <TextField
                                                autoComplete={false}
                                                defaultValue={setting.website}
                                                fullWidth
                                                dir="ltr"
                                                onChange={(e) => setSetting({ ...setting, website: e.target.value })}
                                                 variant={'filled'}

                                                type="text" ></TextField>
                                        </Box>
                                    </Grid>
                                    <Grid xs={12} md={6}>
                                        <Box p={1}>
                                            <label>whatsapp</label>
                                            <TextField
                                                autoComplete={false}
                                                defaultValue={setting.whatsapp}
                                                fullWidth
                                                dir="ltr"
                                                onChange={(e) => setSetting({ ...setting, whatsapp: e.target.value })}
                                                 variant={'filled'}

                                                type="text" ></TextField>
                                        </Box>
                                    </Grid>
                                    <Grid xs={12} md={6}>
                                        <Box p={1}>
                                            <label>telegram</label>
                                            <TextField
                                                autoComplete={false}
                                                defaultValue={setting.telegram}
                                                fullWidth
                                                dir="ltr"
                                                onChange={(e) => setSetting({ ...setting, telegram: e.target.value })}
                                                 variant={'filled'}

                                                type="text" ></TextField>
                                        </Box>
                                    </Grid>
                                    <Grid xs={12} md={6}>
                                        <Box p={1}>
                                            <label>email</label>
                                            <TextField
                                                autoComplete={false}
                                                defaultValue={setting.email}
                                                fullWidth
                                                dir="ltr"
                                                onChange={(e) => setSetting({ ...setting, email: e.target.value })}
                                                 variant={'filled'}

                                                type="text" ></TextField>
                                        </Box>
                                    </Grid>
                                    <Grid xs={12} md={6}>
                                        <Box p={1}>
                                            <label>شماره تماس</label>
                                            <TextField
                                                autoComplete={false}
                                                defaultValue={setting.phone}
                                                fullWidth
                                                dir="ltr"
                                                onChange={(e) => setSetting({ ...setting, phone: e.target.value })}
                                                 variant={'filled'}

                                                type="text" ></TextField>
                                        </Box>
                                    </Grid>
                                    <Grid xs={12} md={6}>
                                        <Box p={1}>
                                            <label>زیر نویس بارکد</label>
                                            <TextField
                                                autoComplete={false}
                                                defaultValue={setting.phone}
                                                fullWidth
                                                dir="ltr"
                                                onChange={(e) => setSetting({ ...setting, solgan: e.target.value })}
                                                 variant={'filled'}

                                                type="text" ></TextField>
                                        </Box>
                                    </Grid>

                                    <Grid xs={12} md={6}>
                                    <Grid xs={12}>
                                        <Container>
                                            <Typography variant="h5" color={'gray'}>عکس صفحه  اصلی</Typography>
                                            <Singlefileuploader component="slidermain" parent={setting['_id']['$oid']} />

                                        </Container>
                                    </Grid>                                    </Grid>

                                </Grid>
                            }
                            <Box pt={6}>
                                <Stack direction={'row'}>
                                    <Box m={1}>
                                        <Button href="/dashboard" variant={'outlined'}>بازگشت</Button>
                                    </Box>
                                    <Box m={1}>
                                        <Button type="submit" variant={'contained'}>ذخیره سازی</Button>
                                    </Box>

                                </Stack>

                            </Box>
                        </Box>
                    </form>
                </Box>
            </Paper>
        </Container>
    )
}