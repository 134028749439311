import { Box, Container, Grid, Link, Typography } from "@mui/material";
import { Mneuitem } from "../data/menu";
import { useCookies, Cookies } from 'react-cookie';

export default function Dashboard() {
    const [cookies] = useCookies(['rule']);

    return (
        <>
            <Box
            dir="rtl"
                sx={{
                    backgroundImage: 'url(/wall.jpg)',
                    'background-blend-mode': 'lighten',

                    width: '100%',
                    backgroundSize: 'cover',
                    'backdrop-filter': 'sepia(20%)',
                    backgroundPosition: 'left',
                    height: '90vh'
                }}
            >
                <Grid
                    container
                >
                    {Mneuitem.map((item) => {
                             
                        return (
                            item.per.includes(cookies.rule) == true &&  
                            <Grid sm={1} pt={2} xs={3} textAlign={'center'}>
                                <Link underline="none" href={item.url}>
                                    <Box textAlign={'center'}>
                                        <Box borderRadius={'90%'} bgcolor={'#1C274C'} mr={'auto'} ml={'auto'} textAlign={'center'} pt={1.5} width={60} height={60}>
                                            <img width={35} src={item.icon}></img>
                                        </Box>
                                        <Box pt={1} textAlign={'center'}>
                                            <Typography color={'white'} fontWeight={'bold'} fontSize={12} textAlign={'center'}>{item.name}</Typography>
                                        </Box>
                                    </Box>
                                </Link>
                            </Grid>
                        )
                    })}
                </Grid>
            </Box>
        </>
    )
}