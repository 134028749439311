
import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Button, Container, Grid, Typography, Link } from '@mui/material';
import { useCookies } from 'react-cookie';
import axios from "axios";
import { config } from "../../data/config";
import Swal from 'sweetalert2'
import { Edit, DeleteForever, Star, Map, ArrowUpward, ArrowDownward, Share } from '@mui/icons-material';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { useEffect } from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { useParams } from 'react-router-dom';

function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
}



export default function ListEstate() {
    let { Id, Parent } = useParams();

    const [cookies, setCookie] = useCookies(['token', 'rule']);
    const [page, setPage] = React.useState(1);
    const [data, setData] = React.useState(null);
    const [cat, setCat] = React.useState(null);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        reload()
    };
    const totop = async (item) => {
        try {
            var path = `${config.url}/neighborhood/totop/${item}`;
            let res = await axios({
                method: 'put',
                url: path,
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': `multipart/form-data`,
                    'Authorization': cookies.token
                }
            });
            reload()
            // setData(res.data.message)
        } catch (error) {
        }
    }
    const todown = async (item) => {
        try {
            var path = `${config.url}/neighborhood/todown/${item}`;
            let res = await axios({
                method: 'put',
                url: path,
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': `multipart/form-data`,
                    'Authorization': cookies.token
                }
            });
            reload()
            // setData(res.data.message)
        } catch (error) {
        }
    }
    const loadcat = async () => {
        var path = `${config.url}/aria/${Id}`;
        try {
            let res = await axios({
                method: 'post',
                url: path,
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "*",
                    'Authorization': cookies.token
                }
            });
            setCat(res.data)


        } catch (error) {
            console.log(error)
        }

    }
    const deleteforover = async (e) => {
        try {
            var path = `${config.url}/estate/${e}`;
            let res = await axios({
                method: 'delete',
                url: path,
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': `multipart/form-data`,
                    'Authorization': cookies.token
                }
            });
            reload()
            // setData(res.data.message)
        } catch (error) {
            Swal.fire('can not be delete iteam')
        }
    }
    const reload = async () => {
        var path = `${config.url}/estate?limit=10&page=${page}&parent=${Id}`;
        try {
            let res = await axios({
                method: 'get',
                url: path,
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "*",
                    'Authorization': cookies.token
                }
            });
            setData(res.data)


        } catch (error) {
            console.log(error)
        }

    }
    useEffect(() => {
        reload();
        loadcat();
    }, []);
    return (
        <Box dir="rtl">

            <Container>
                <Box pb={3} pt={3}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" href="/dashboard">
                        داشبورد
                        </Link>
                        <Typography color="text.primary">لیست محصولات</Typography>
                    </Breadcrumbs>
                    <Typography variant='h1' color={'gray'} fontSize={34}>
                        لیست محصولات
                    </Typography>
                </Box>
                <Paper >
                    <Container>
                        <Box pt={4}>
                            <Stack direction={'row'} spacing={1}>
                                {
                                    cat != null && <Button variant={'contained'} href={`/dashboard/neighborhood/add/${Id}/${Parent}`}>Add New Neighborhood</Button>
                                }


                                <Button variant={'contained'} onClick={(e) => { reload() }}>دوباره خوانی</Button>
                            </Stack>
                        </Box>
                    </Container>
                    {
                        data != null &&
                        <Box pt={4} p={4}>

                            <TableContainer component={Paper}>

                                <Table sx={{ minWidth: 650 }} aria-label="caption table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell textAlign={'right'} align='right'>نام</TableCell>
                                            <TableCell  textAlign={'center'}  align='center'>فعالیت</TableCell>

                                        </TableRow>
                                    </TableHead>
                                    <TableBody>

                                        {data.docs.map((row) => (
                                            <TableRow key={row.name}>
                                                <TableCell component="th" scope="row"  textAlign={'right'} align='right'>
                                                    {row.name} {row.rule == 1 && <Star fontSize='small' color='primary'></Star>}
                                                </TableCell>
                                                <TableCell  textAlign={'center'}  align='center'>
                                                    <Stack textAlign={'center'} direction={'row'} spacing={3}>
                                                        <Link href={`/dashboard/product/Edit/${row._id['$oid']}`} underline='none' color={'#333'}>
                                                            <Edit />

                                                        </Link>
                                                       
                                                        <Button onClick={(e) => { deleteforover(row._id['$oid']) }}>
                                                            <DeleteForever />

                                                        </Button>

                                                    </Stack>
                                                </TableCell>
                                             
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Box p={2}>
                                <Pagination
                                    onChange={handleChangePage}
                                    defaultPage={data.page}
                                    count={data.totalpage}
                                    color="primary"
                                />
                            </Box>
                        </Box>
                    }
                </Paper>
            </Container>
        </Box>
    );
}