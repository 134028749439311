import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./layout/layout";
import AuthPage from "./pages/Auth";
import AuthLayout from './layout/authlayout';
import DashboardLayout from './layout/layout';
import Dashboard from './pages/Dashborad';
import UserList from './pages/Users/userlist';
import NewUser from './pages/Users/newuser';
import UserEdit from './pages/Users/useredit';
import FeatureList from './pages/Estate/featureList';
import NewFeature from './pages/Estate/newfeature';
import EditFeature from './pages/Estate/editfeature';
import CategoryList from './pages/Estate/categorylist';
import NewCat from './pages/Estate/categorynew';
import EditCateogry from './pages/Estate/editcategpry';
import AreaList from './pages/Estate/citylist';
import NewCity from './pages/Estate/citynew';
import CityEdit from './pages/Estate/cityedit';
import ZonesList from './pages/Estate/zoneslist';
import NewZone from './pages/Estate/newzone';
import ZoneEdit from './pages/Estate/zoneedit';
import NeighborhoodList from './pages/Estate/neighborhoodlist'
import NeighborhoodAdd from './pages/Estate/neighborhoodadd';
import NeighborhoodEdit from './pages/Estate/neighborhoodEdit';
import NewEstate from './pages/Home/newproduct';
import EditEstate from './pages/Home/editestate';
import ListEstate from './pages/Home/listestate'
import SharePage from './pages/SahreDetail';
import AdvancedSearch from './pages/Home/advnacedsearch';
import Logout from './pages/logout';
import BarcodeList from './pages/Home/barcodelist';
import Setting from './pages/Home/setting';
function App() {

  return (
    <BrowserRouter>

      <Routes>
        <Route path="/" element={<AuthLayout />}>
          <Route index element={<AuthPage />} />

        </Route>
        <Route path="/logout" element={<Logout />} />

        <Route path="view/:Id" element={<SharePage />} />


        <Route path="/dashboard" element={<DashboardLayout />}>
          <Route index element={<Dashboard />} />

          <Route path="users" element={<UserList />} />
          <Route path="users/new" element={<NewUser />} />
          <Route path="users/edit/:Id" element={<UserEdit />} />

          <Route path="feature" element={<FeatureList />} />
          <Route path="feature/new" element={<NewFeature />} />
          <Route path="feature/edit/:Id" element={<EditFeature />} />

          <Route path="category" element={<CategoryList />} />
          <Route path="category/new" element={<NewCat />} />
          <Route path="category/edit/:Id" element={<EditCateogry />} />

          <Route path="area" element={<AreaList />} />

          <Route path="city/new" element={<NewCity />} />
          <Route path="city/edit/:Id" element={<CityEdit />} />

          <Route path="zones/:Id" element={<ZonesList />} />
          <Route path="zones/add/:Id" element={<NewZone />} />
          <Route path="zones/edit/:Id" element={<ZoneEdit />} />

          <Route path="neighborhood/add/:Id/:Parent" element={<NeighborhoodAdd />} />
          <Route path="neighborhood/:Id/:Parent" element={<NeighborhoodList />} />
          <Route path="neighborhood/edit/:Id/:Parent" element={<NeighborhoodEdit />} />

          <Route path="product" element={<NewEstate />} />
          <Route path="Barcodelist/:Id" element={<BarcodeList />} />

          <Route path="product/Edit/:Id" element={<EditEstate />} />
          <Route path="setting" element={<Setting />} />
          <Route path="list" element={<ListEstate />} />
          <Route path="search" element={<AdvancedSearch />} />










        </Route>
      </Routes>
    </BrowserRouter>

  );
}

export default App;
