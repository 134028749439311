import { Box, Breadcrumbs, Button, Container, Grid, Link, MenuItem, Paper, Select, Stack, TextField, Typography } from "@mui/material";
import { useForm } from 'react-hook-form';
import axios from "axios";
import { config } from "../../data/config";
import Swal from 'sweetalert2'
import { useCookies } from 'react-cookie';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import * as React from 'react';
import SingleFileuploader from "../../component/singlefileuploader";

export default function NewUser() {
    let { Id } = useParams();

    const [cookies, setCookie] = useCookies(['token', 'rule']);
    const [data, setData] = React.useState(null);

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
    const load = async () => {
        try {
            var path = `${config.url}/usermanager/${Id}`;

            let res = await axios({
                method: 'get',
                url: path,
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': `multipart/form-data`,
                    'Authorization': cookies.token
                }
            });
            console.log(res);
            setData(res.data.message)
        } catch (error) {

        }
    }
    useEffect(() => {
        load();
    }, []);
    const changepassword = async()=>{
        Swal.fire({
            title: "Change Password",
            input: "text",
            inputValue: '',
            inputOptions:{
                type:'password'
            },
            inputAttributes: {
              autocapitalize: "off",
      
            },
      
            showCancelButton: true,
            confirmButtonText: "Change Password",
            showLoaderOnConfirm: true,
            preConfirm: async (login) => {
              try {
                var path = `${config.url}/usermanager/changepassword/${data['_id']['$oid']}`;
                let res = await axios({
                  method: 'put',
                  data: {
                    password: login
                  },
                  url: path,
                  headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': `multipart/form-data`,
                    'Authorization': cookies.token
                  }
                });
                Swal.fire('Password Change Success')
                // setData(res.data.message)
              } catch (error) {
                Swal.fire({
                    title: error.response.data.message,
                    html: `<div>
                ${Object.keys(error.response.data.error).map((field) => (
                        ` <div key=${field}>
                 ${error.response.data.error[field].map((message) => (
                            `<a style="font-size:15px"  key={message}> ${message}</a>`
                        ))}
               </div>`
                    ))}
              </div>`

                })
                
              }
            },
            allowOutsideClick: () => !Swal.isLoading()
          }).then((result) => {
            if (result.isConfirmed) {
            //   Swal.fire({
            //     title: `Success`,
      
            //   });
            }
          });
    }
    const submit = async (data2) => {
        var path = `${config.url}/usermanager/${data['_id']['$oid']}`;
        console.log(data)
        try {
            let res = await axios({
                method: 'put',
                url: path,
                data: data2,
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': `multipart/form-data`,
                    'Authorization': cookies.token
                }
            });

            window.location.href = '/dashboard/users';


        } catch (error) {
            console.log('error')
            Swal.fire(error.response.data.message)
            error.response.data.error == null ?
                Swal.fire(error.response.data.message)
                : 
                Swal.fire({
                    title: error.response.data.message,
                    html: `<div>
                ${Object.keys(error.response.data.error).map((field) => (
                        ` <div key=${field}>
                 ${error.response.data.error[field].map((message) => (
                            `<a style="font-size:15px"  key={message}> ${message}</a>`
                        ))}
               </div>`
                    ))}
              </div>`

                })
        }

    }

    return (
        <Container dir="rtl">
            <Box pb={3} pt={3}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" href="/dashboard">
                        داشبورد
                    </Link>
                    <Link underline="hover" color="inherit" href="/dashboard/users">
                        مدیریت کاربر
                    </Link>
                    <Typography color="text.primary">مدیریت کاربر</Typography>
                </Breadcrumbs>
                <Typography variant='h1' color={'gray'} fontSize={34}>مدیریت کاربر</Typography>
            </Box>
            <Paper>
                <Box>
                    {data != null &&
                        <form onSubmit={handleSubmit((data) => submit(data))} >
                            <Box p={4}
                                borderRadius={2}>
                                <Grid container>
                                    <Grid xs={12} sm={4}>
                                        <SingleFileuploader component="users" parent={data['_id']['$oid']} />
                                    </Grid>
                                    <Grid xs={12} sm={8}>
                                        <Grid container>
                                            <Grid xs={12} md={12}>
                                                <Box p={1}>
                                                    <TextField
                                                        fullWidth
                                                        {...register('name', { required: true })} defaultValue={data.name}
                                                        required variant={'filled'} label="khl" type="text" ></TextField>
                                                </Box>
                                            </Grid>
                                            <Grid xs={12} md={12}>
                                                <Box p={1}>
                                                    <TextField
                                                        fullWidth
                                                        {...register('email', { required: true })} defaultValue={data.email}
                                                        required variant={'filled'} label="E-mail" type="email" placeholder="Sample@mail.com"></TextField>
                                                </Box>
                                            </Grid>
                                            <Grid xs={12} md={12}>
                                                <Box p={1}>
                                                    <TextField
                                                        fullWidth
                                                        {...register('mobile', { required: true, min: 8 })} defaultValue={data.mobile}
                                                        required variant={'filled'} label="شماره ها" placeholder="mobile"></TextField>
                                                </Box>
                                            </Grid>
                                            <Grid xs={12} md={6}>
                                                <Box p={1}>
                                                    <label>فعال</label>
                                                    <Select
                                                        {...register('active')}
                                                        defaultValue={data.active}
                                                        label={'active'}
                                                        fullWidth
                                                        variant={'filled'}
                                                    >
                                                        <MenuItem value={true}>True</MenuItem>
                                                        <MenuItem value={false}>False</MenuItem>

                                                    </Select>

                                                </Box>
                                            </Grid>
                                            <Grid xs={12} md={6}>
                                                <Box p={1}>
                                                    <label>وظیفه</label>
                                                    <Select
                                                        {...register('rule')}
                                                        defaultValue={data.rule}
                                                        label={'rule'}
                                                        fullWidth
                                                        variant={'filled'}
                                                    >
                                                        <MenuItem value={1}>Super Admin</MenuItem>
                                                        <MenuItem value={2}>Employee</MenuItem>
                                                        <MenuItem value={3}>Viewer</MenuItem>

                                                    </Select>

                                                </Box>
                                            </Grid>

                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Box pt={6}>
                                    <Stack direction={'row'}>
                                        <Box m={1}>
                                            <Button type="submit" variant={'contained'}>آپدیت</Button>
                                        </Box>
                                        <Box m={1}>
                                            <Button  onClick={(e)=>{ changepassword() } }variant={'contained'} color={'secondary'}>تغییر رمز</Button>
                                        </Box>
                                        <Box m={1}>
                                            <Button href="/dashboard/users" variant={'outlined'}>برگشت</Button>
                                        </Box>

                                    </Stack>

                                </Box>
                            </Box>
                        </form>
                    }
                </Box>
            </Paper>
        </Container>
    )
}