import { Box, Breadcrumbs, Button, Container, Grid, Link, MenuItem, Paper, Select, Stack, TextField, Typography } from "@mui/material";
import { useForm } from 'react-hook-form';
import axios from "axios";
import { config } from "../../data/config";
import Swal from 'sweetalert2'
import { useCookies } from 'react-cookie';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import * as React from 'react';
import SingleFileuploader from "../../component/singlefileuploader";

export default function NeighborhoodEdit() {
    let { Id, Parent } = useParams();

    const [cookies, setCookie] = useCookies(['token', 'rule']);
    const [data, setData] = React.useState(null);

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
    const load = async () => {
        try {
            var path = `${config.url}/neighborhood/${Id}`;

            let res = await axios({
                method: 'get',
                url: path,
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': `multipart/form-data`,
                    'Authorization': cookies.token
                }
            });
            setData(res.data)
        } catch (error) {

        }
    }


    const submit = async (data2) => {
        var path = `${config.url}/neighborhood/${data['_id']['$oid']}`;
        console.log(data)
        try {
            let res = await axios({
                method: 'put',
                url: path,
                data: data2,
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': `multipart/form-data`,
                    'Authorization': cookies.token
                }
            });
            // if (data != null) { window.location.href = '/dashboard/zones/' + Parent; }
            window.history.back()

        } catch (error) {
            console.log('error')
            Swal.fire(error.response.data.message)
            error.response.data.error == null ?
                Swal.fire(error.response.data.message)
                :
                Swal.fire({
                    title: error.response.data.message,
                    html: `<div>
                ${Object.keys(error.response.data.error).map((field) => (
                        ` <div key=${field}>
                 ${error.response.data.error[field].map((message) => (
                            `<a style="font-size:15px"  key={message}> ${message}</a>`
                        ))}
               </div>`
                    ))}
              </div>`

                })
        }

    }
    useEffect(() => {
        load();
    }, []);
    return (
        <Container>
            <Box pb={3} pt={3}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" href="/dashboard">
                        Dashboard
                    </Link>
                    <Link underline="hover" color="inherit" >
                    Neighborhood List
                    </Link>
                    <Link underline="hover" color="inherit" href={`/dashboard/neighborhood/${Parent}`}>
                        List of  Neighborhood
                    </Link>
                    <Typography color="text.primary">Edit Neighborhood</Typography>
                </Breadcrumbs>
                <Typography variant='h1' color={'gray'} fontSize={34}>Edit Neighborhood</Typography>
            </Box>
            <Paper>
                <Box>


                    {data != null &&
                        <form onSubmit={handleSubmit((data) => submit(data))} >
                            <Box p={4}
                                borderRadius={2}>
                                <Grid container>

                                    <Grid xs={12} sm={8}>
                                        <Grid container>
                                            <Grid xs={12} md={12}>
                                                <Box p={1}>
                                                    <TextField
                                                        fullWidth
                                                        {...register('name', { required: true })} defaultValue={data.name}
                                                        required variant={'filled'} label="name" type="text" ></TextField>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Box pt={6}>
                                    <Stack direction={'row'}>
                                        <Box m={1}>
                                            <Button type="submit" variant={'contained'}>Update</Button>
                                        </Box>
                                        <Box m={1}>
                                            <Button href="/dashboard/area" variant={'outlined'}>Back to List</Button>
                                        </Box>
                                    </Stack>
                                </Box>
                            </Box>
                        </form>
                    }
                </Box>
            </Paper>
        </Container>
    )
}