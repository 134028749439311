import { Box, Button, Container, TextField, Typography } from "@mui/material";
import { useForm } from 'react-hook-form';
import axios from "axios";
import { config } from "../data/config";
import Swal from 'sweetalert2'
import { useCookies } from 'react-cookie';
import { useEffect } from 'react';

import withReactContent from 'sweetalert2-react-content'

export default function AuthPage() {
    const [cookies, setCookie] = useCookies(['token', 'rule']);

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
    const submit = async (data) => {
        var path = `${config.url}/auth`;
        console.log(data)
        try {
            let res = await axios({
                method: 'post',
                url: path,
                data: data,
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': `multipart/form-data`,
                }
            });
            setCookie('token', res.data.token);
            setCookie('rule', res.data.rule);
            window.location.href = '/dashboard';


        } catch (error) {
            console.log('error')
            Swal.fire(error.response.data.message)
        }

    }
    useEffect(() => {
        if(cookies['token']!=undefined){
            window.location.href = '/dashboard';
        }
    }, []);

    return (
        <>
            <Box textAlign={'center'} pt={13}>
                <Container>
                    <form onSubmit={handleSubmit((data) => submit(data))} >
                        <Box p={4} border={'solid 0.5px #333'}
                            bgcolor={'#222'}
                            borderRadius={2}>
                            <Box p={3} width={'100%'} textAlign={'center'}>
                                <img src="/logo.png" width={100}></img>
                                <Typography textAlign={'center'} fontWeight={'bold'}>Login Page</Typography>
                            </Box>
                            <Box>
                                <TextField
                                    {...register('email', { required: true })}
                                    required variant={'filled'} label="E-mail" type="email" placeholder="Sample@mail.com"></TextField>
                            </Box>
                            <Box mt={5}>
                                <TextField
                                    {...register('password', { required: true, min: 8 })}
                                    required variant={'filled'} label="Password" type="password" placeholder="Password"></TextField>
                                {errors.password && <p>Please enter number for age.</p>}

                            </Box>

                            <Box pt={6}>
                                <Button type="submit" variant={'contained'}>Login</Button>
                            </Box>
                        </Box>
                    </form>
                </Container>
                <footer style={{ color: "gray", position: "fixed", bottom: 30, width: '100%', textAlign: 'center' }}>
                    <right><Typography textAlign={'center'} fontSize={12}>Desing team <a href="https://afrang.dev">Afrang</a> - Powered by PyTISS 2023</Typography></right>
                </footer>

            </Box>

        </>
    )
}