import { Box, Breadcrumbs, Button, Container, Grid, Link, MenuItem, Paper, Select, Stack, TextField, Typography } from "@mui/material";
import { useForm } from 'react-hook-form';
import axios from "axios";
import { config } from "../../data/config";
import Swal from 'sweetalert2'
import { useCookies } from 'react-cookie';
import { useEffect } from 'react';
import * as React from 'react';
import changeUrls from '../../hoc/changeurl';
export default function NewCat() {
    const [cookies, setCookie] = useCookies(['token', 'rule']);
    const [listgroup, setList] = React.useState(null);
    const [url, setUrl] = React.useState(null);
    const [addon, setAddon] = React.useState('');


    const handleChange = (event) => {
        setAddon(event.target.value);
    };

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
    const changeurl = (data) => {
        setUrl(changeUrls(data))
    }
    const submit = async (data2) => {
        var path = `${config.url}/cat`;
        var mydata = {
            name: data2.name,
            url: data2.url,
            addon: addon,

        };
        console.log(mydata);


        try {
            let res = await axios({
                method: 'post',
                url: path,
                data: mydata,
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': `application/json`,

                    'Authorization': cookies.token
                }
            });

             window.location.href = '/dashboard/category';


        } catch (error) {
            Swal.fire(error.response.data.message)
            error.response.data.error == null ?
                Swal.fire(error.response.data.message)
                : Swal.fire({
                    title: error.response.data.message,
                    html: `<div>
                ${Object.keys(error.response.data.error).map((field) => (
                        ` <div key=${field}>
                 ${error.response.data.error[field].map((message) => (
                            `<a style="font-size:15px"  key={message}> ${message}</a>`
                        ))}
               </div>`
                    ))}
              </div>`

                })
        }

    }
    const reload = async () => {
        var path = `${config.url}/feature/all`;

        try {
            let res = await axios({
                method: 'get',
                url: path,
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': `multipart/form-data`,
                    'Authorization': cookies.token
                }
            });
            setList(res.data)


        } catch (error) {

        }
    }
    useEffect(() => {
        reload();
    }, []);
    return (
        <Container dir="rtl">
            <Box pb={3} pt={3}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" href="/dashboard">
                    داشبورد
                    </Link>
                    <Link underline="hover" color="inherit" href="/dashboard/category">
                    لیست دسته بندی ها
                    </Link>
                    <Typography color="text.primary">جدید</Typography>
                </Breadcrumbs>
                <Typography variant='h1' color={'gray'} fontSize={34}>دسته بندی جدید</Typography>
            </Box>
            <Paper>
                <Box >
                    <form onSubmit={handleSubmit((data) => submit(data))} >
                        <Box p={4}
                            borderRadius={2}>
                            <Grid container>
                                <Grid xs={12} md={6}>
                                    <Box p={1}>
                                        <TextField
                                            fullWidth
                                            dir="rtl"

                                            onKeyUp={(e) => { changeurl(e.target.value) }}
                                            {...register('name', { required: true })}
                                            required variant={'outlined'} label="نام" type="text" ></TextField>
                                    </Box>
                                </Grid>
                                <Grid xs={12} md={6}>
                                    <Box p={1}>
                                        <TextField
                                            dir="rtl"
                                            fullWidth
                                            value={url}
                                            {...register('url', { required: true })}
                                            required variant={'outlined'} label="url" type="text" ></TextField>
                                    </Box>
                                </Grid>

                             

                            </Grid>
                            <Box pt={6}>
                                <Stack direction={'row'}>
                                    <Box m={1}>
                                        <Button type="submit" variant={'contained'}>اضافه کردن </Button>
                                    </Box>
                                    <Box m={1}>
                                        <Button href="/dashboard/category" variant={'outlined'}>برگشتن</Button>
                                    </Box>
                                </Stack>

                            </Box>
                        </Box>
                    </form>
                </Box>
            </Paper>
        </Container>
    )
}