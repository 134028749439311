import { Box, Breadcrumbs, Button, Container, Grid, Link, Paper, Stack, TextField, Typography } from "@mui/material";
import { useForm } from 'react-hook-form';
import axios from "axios";
import { config } from "../../data/config";
import Swal from 'sweetalert2'
import { useCookies } from 'react-cookie';

export default function NewUser() {
    const [cookies, setCookie] = useCookies(['token', 'rule']);

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
    const submit = async (data) => {
        var path = `${config.url}/users`;
        console.log(data)
        try {
            let res = await axios({
                method: 'post',
                url: path,
                data: data,
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': `multipart/form-data`,
                    'Authorization': cookies.token
                }
            });

            window.location.href = '/dashboard/users';


        } catch (error) {
            console.log('error')
            Swal.fire(error.response.data.message)
            error.response.data.error == null ?
                Swal.fire(error.response.data.message)
                : Swal.fire({
                    title: error.response.data.message,
                    html: `<div>
                ${Object.keys(error.response.data.error).map((field) => (
                        ` <div key=${field}>
                 ${error.response.data.error[field].map((message) => (
                            `<a style="font-size:15px"  key={message}> ${message}</a>`
                        ))}
               </div>`
                    ))}
              </div>`

                })
        }

    }

    return (
        <Container dir="rtl">
            <Box pb={3} pt={3}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" href="/dashboard">
                        داشبورد
                    </Link>
                    <Link underline="hover" color="inherit" href="/dashboard/users">
                        مدیریت کاربران
                    </Link>
                    <Typography color="text.primary"> کاربرد جدید</Typography>
                </Breadcrumbs>
                <Typography variant='h1' color={'gray'} fontSize={34}>کاربر جدید</Typography>
            </Box>
            <Paper>
                <Box>
                    <form onSubmit={handleSubmit((data) => submit(data))} >
                        <Box p={4}
                            borderRadius={2}>
                            <Grid container>
                                <Grid xs={12} md={6}>
                                    <Box p={1}>
                                        <TextField
                                            fullWidth
                                            {...register('name', { required: true })}
                                            required variant={'filled'} label="نام" type="text" ></TextField>
                                    </Box>
                                </Grid>
                                <Grid xs={12} md={6}>
                                    <Box p={1}>
                                        <TextField
                                            fullWidth
                                            {...register('email', { required: true })}
                                            required variant={'filled'} label="E-mail" type="email" placeholder="Sample@mail.com"></TextField>
                                    </Box>
                                </Grid>
                                <Grid xs={12} md={6}>
                                    <Box p={1}>
                                        <TextField
                                            fullWidth
                                            {...register('password', { required: true, min: 8 })}
                                            required variant={'filled'} label="رمز" type="password" placeholder="Password"></TextField>
                                    </Box>
                                </Grid>
                                <Grid xs={12} md={6}>
                                    <Box p={1}>
                                        <TextField
                                            fullWidth
                                            {...register('confirm', { required: true, min: 8 })}
                                            required variant={'filled'} label="تایید رمز" type="password" placeholder="Confirm Password"></TextField>
                                    </Box>
                                </Grid>
                                <Grid xs={12} md={6}>
                                    <Box p={1}>
                                        <TextField
                                            fullWidth
                                            {...register('mobile', { required: true, min: 8 })}
                                            required variant={'filled'} label="موبایل" placeholder="mobile"></TextField>


                                    </Box>
                                </Grid>
                            </Grid>
                            <Box pt={6}>
                                <Stack direction={'row'}>
                                    <Box m={1}>
                                        <Button type="submit" variant={'contained'}>ثبت نام </Button>
                                    </Box>
                                    <Box m={1}>
                                        <Button href="/dashboard/users" variant={'outlined'}>بازگشت</Button>
                                    </Box>
                                </Stack>

                            </Box>
                        </Box>
                    </form>
                </Box>
            </Paper>
        </Container>
    )
}