export const Mneuitem=[
    {
      url:'/dashboard/product',
      name:'محصول جدید',
      icon:'/assets/add.svg',
      per:[1,2]
    },
   
    {
      url:'/dashboard/list',
      name:'لیست محصولات و بارکد',
      icon:'/assets/list.svg',

      per:[1,2]

    },
   
    {
      url:'/dashboard/category',
      name:'دسته بندی',
      icon:'/assets/folder.svg',
      per:[1]
    },
    {
      url:'/dashboard/setting',
      name:'تنظیمات',
      icon:'/assets/setting.svg',
      per:[1]
    },
    
    {
      url:'/dashboard/users',
      name:'کاربران',
      icon:'/assets/users.svg',
      per:[1]
    },
    
    {
      url:'/logout',
      name:'خروج',
      icon:'/logout.svg',
      per:[1,2]
    }
  ]