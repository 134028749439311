import { Box, Breadcrumbs, Button, Container, Grid, Link, MenuItem, Paper, Select, Stack, TextField, Typography } from "@mui/material";
import { useForm } from 'react-hook-form';
import axios from "axios";
import { config } from "../../data/config";
import Swal from 'sweetalert2'
import { useCookies } from 'react-cookie';
import { useParams } from 'react-router-dom';

export default function NeighborhoodAdd() {
    const [cookies, setCookie] = useCookies(['token', 'rule']);
    let { Id } = useParams();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
    const submit = async (data) => {

        var path = `${config.url}/neighborhood`;
        console.log(path);
        var mydata = {
            name: data.name,
            parent: Id

        };



        try {
            let res = await axios({
                method: 'post',
                url: path,
                data: mydata,
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': `application/json`,
                    'Authorization': cookies.token

                }
            });

            window.history.back()


        } catch (error) {
            console.log('error')
            Swal.fire(error.response.data.message)
            error.response.data.error == null ?
                Swal.fire(error.response.data.message)
                : Swal.fire({
                    title: error.response.data.message,
                    html: `<div>
                ${Object.keys(error.response.data.error).map((field) => (
                        ` <div key=${field}>
                 ${error.response.data.error[field].map((message) => (
                            `<a style="font-size:15px"  key={message}> ${message}</a>`
                        ))}
               </div>`
                    ))}
              </div>`

                })
        }

    }

    return (
        <Container>
            <Box pb={3} pt={3}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" href="/dashboard">
                        Dashboard
                    </Link>
                    <Link underline="hover" color="inherit" href={`/dashboard/area`}>
                        City List
                    </Link>
                    <Link underline="hover" color="inherit" href={`/dashboard/zones/${Id}`}>
                        Zones List
                    </Link>

                    <Typography color="text.primary">New Neighborhood</Typography>
                </Breadcrumbs>
                <Typography variant='h1' color={'gray'} fontSize={34}>New Neighborhood</Typography>
            </Box>
            <Paper>
                <Box>
                    <form onSubmit={handleSubmit((data) => submit(data))} >
                        <Box p={4}
                            borderRadius={2}>
                            <Grid container>
                                <Grid xs={12} md={12}>
                                    <Box p={1}>
                                        <TextField
                                            fullWidth
                                            {...register('name', { required: true })}
                                            required variant={'filled'} label="name" type="text" ></TextField>
                                    </Box>
                                </Grid>




                            </Grid>
                            <Box pt={6}>
                                <Stack direction={'row'}>
                                    <Box m={1}>
                                        <Button type="submit" variant={'contained'}>Add</Button>
                                    </Box>
                                    <Box m={1}>
                                        <Button href="/dashboard/feature" variant={'outlined'}>Back to List</Button>
                                    </Box>
                                </Stack>

                            </Box>
                        </Box>
                    </form>
                </Box>
            </Paper>
        </Container>
    )
}